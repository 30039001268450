<div class="container-fluid g-0">
  <!-- Progress Circle -->
  <div class="row g-0">
    <div class="col-12 d-flex justify-content-center">
      <ng-container *ngIf="statusData._id">
        <!-- When _id is present, a transaction is in progress, we distinguish between COMMAND transactions and charge transactions -->
        <eld-progress-circle
          icon="plug"
          [style]="{ size: 200, strokeWidth: 11, showText: true }"
          textSize="small"
          [animation]="true"
          *ngIf="statusData.status.startsWith('COMMAND'); else charge"
        >
          <ng-container text>
            {{ 'STATUS.' + statusData.status | translate }}
          </ng-container>
        </eld-progress-circle>
        <ng-template #charge>
          <eld-progress-circle
            icon="bolt"
            [style]="{ size: 200, strokeWidth: 11, showText: true }"
            [value]="statusData.soc || 100"
            [secondaryValue]="statusData.targetSoC"
            [animation]="!statusData.stopDate">
            <ng-container text  *ngIf="statusData.soc">
              {{ statusData.soc }} %
            </ng-container>
          </eld-progress-circle>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="!statusData._id" [ngSwitch]="statusData.status">
        <ng-container *ngSwitchCase="'AVAILABLE'">
          <eld-progress-circle
            icon="plug"
            [style]="{size: 200, strokeWidth: 11, showText: true}"
            textSize="small">
            <ng-container text>
              {{ 'STATUS.AVAILABLE' | translate }}
            </ng-container>
          </eld-progress-circle>
        </ng-container>
        <ng-container *ngSwitchCase="'OFFLINE'">
          <!-- check if "just went offline" here -->
          <ng-container *ngIf="statusData.offlineTime > 5 * 60">
            <eld-progress-circle
              icon="danger"
              [style]="{size: 200, strokeWidth: 11, showText: true}"
              textSize="small">
              <ng-container text>
                {{ 'STATUS.OFFLINE' | translate }}
              </ng-container>
            </eld-progress-circle>
          </ng-container>
          <ng-container *ngIf="statusData.offlineTime <= 5 * 60">
            <eld-progress-circle
              icon="spinner"
              [style]="{size: 200, strokeWidth: 11, showText: true}"
              textSize="small">
              <ng-container text>
                <span [innerHTML]="('LAST_SEEN_MINUTES_AGO' | translate: { time: (statusData.offlineTime | secondsToTime) })"></span>
              </ng-container>
            </eld-progress-circle>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <eld-progress-circle
            icon="plug"
            [style]="{size: 200, strokeWidth: 11, showText: true}"
            textSize="small">
            <ng-container text>
              {{ 'STATUS.' + statusData.status | translate }}
            </ng-container>
          </eld-progress-circle>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-center text-2 lineh-3">
      <ng-container *ngIf="statusData._id">
        <ng-container *ngIf="statusData.status !== 'PRECHARGING'">
          <ng-container *ngIf="statusData.targetSoC && statusData.targetSoC !== 100">
            {{ 'T_SOC' | translate }} {{ statusData.targetSoC }} %
          </ng-container>
        </ng-container>
        <ng-container *ngIf="statusData.status === 'PRECHARGING'">
          {{ 'STATUS.' + statusData.status | translate }}
        </ng-container>
        <br>
        
        <ng-container *ngIf="statusData.elapsedTime">
          {{ 'CT' | translate }} {{ statusData.elapsedTime | secondsToTime }}
        </ng-container>
        <ng-container *ngIf="!statusData.stopDate && statusData.remainingTime && statusData.targetSoC && statusData.status !== 'PRECHARGING'">
          {{ 'ECT' | translate }} {{ statusData.remainingTime | secondsToTime }}
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!statusData._id && statusData.status === 'OFFLINE'">
        <ng-container *ngIf="statusData.lastSeen && statusData.offlineTime < 24 * 3600">
          {{ 'LAST_SEEN' | translate }}: {{ statusData.lastSeen | date: 'HH:mm' }}<br>&nbsp;
        </ng-container>
        <ng-container *ngIf="statusData.lastSeen && statusData.offlineTime >= 24 * 3600">
          {{ 'LAST_SEEN' | translate }}: {{ statusData.lastSeen | date: 'd/M/yy HH:mm' }}<br>&nbsp;
        </ng-container>
      <ng-container *ngIf="!statusData.lastSeen">
        &nbsp;<br>&nbsp;
      </ng-container>
      </ng-container>
      <ng-container *ngIf="!statusData._id && statusData.status !== 'OFFLINE'">
        &nbsp;<br>&nbsp;
      </ng-container>
    </div>
  </div>
  

  <!-- Progress Bars -->
  <div class="row g-0" *ngIf="statusData._id && !statusData.status.startsWith('COMMAND'); else placeholderBar">
    <div class="col d-flex">
      <eld-progress-bar
        class="flex-grow-1 d-flex flex-column"
        [value]="
          !statusData.powerLimit || statusData.stopDate
            ? 100
            : (statusData.power || 0) / statusData.powerLimit * 100
        "
        [animation]="!statusData.stopDate"
      >
        <span valueText class="text-3 lineh-4-5">
          <span class="fw-600" *ngIf="statusData.stopDate">&Oslash; {{ getAveragePower(statusData) }}</span>
          <span class="fw-600" *ngIf="!statusData.stopDate">{{ statusData.power }}</span>
          <span class="text-2">&nbsp;kW</span>
        </span>
        <span additionalText>
          <ng-container *ngIf="!statusData.stopDate && statusData.current && statusData.voltage">
            ({{ statusData.current }} A · {{ statusData.voltage }} V)
          </ng-container>
          &nbsp;
        </span>
        <span label>
          {{ "POWER" | translate }}
        </span>
      </eld-progress-bar>
    </div>

    <div class="col d-flex" *ngIf="!publicDisplay">
      <eld-progress-bar
        *ngIf="statusData.energy !== undefined"
        class="flex-grow-1 d-flex flex-column"
        [value]="
        statusData.targetSoC && statusData.soc && statusData.initialSoC
          ? (statusData.soc - statusData.initialSoC) / (statusData.targetSoC - statusData.initialSoC) * 100
          : 100
        "
        [animation]="!statusData.stopDate"
      >
        <span valueText>
          <ng-container *ngIf="statusData.energy >= 0">
            <span class="fw-600">{{ statusData.energy }}</span>
            <span class="text-2 lineh-2">&nbsp;kWh</span>
          </ng-container>
          &nbsp;
        </span>
        <span additionalText>
          <ng-container *ngIf="statusData.soc !== undefined && statusData.initialSoC !== undefined">
            + {{ statusData.soc - statusData.initialSoC }} %
          </ng-container>
          &nbsp;
        </span>
        <span label>
          {{ "ENERGY" | translate }}
        </span>

      </eld-progress-bar>
    </div>
  </div>

  <!-- placeholder to keep consistent spacing -->
  <ng-template #placeholderBar>
    <div class="row g-0">
      <div class="col d-flex">
        <eld-progress-bar
          class="flex-grow-1 d-flex flex-column visibility-hidden"
          [value]="100"
          [animation]="false"
        >
          <span valueText>&nbsp;</span>
          <span additionalText>&nbsp;</span>
          <span label>&nbsp;</span>
        </eld-progress-bar>
      </div>
    </div>
  </ng-template>
</div>
