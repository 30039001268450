import { Injectable } from '@angular/core'
import { NgxSpinnerService } from 'ngx-spinner'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: `root`
})
export class SpinnerService {
  private spinnerSubject = new BehaviorSubject<boolean>(false)

  constructor (private spinner: NgxSpinnerService) { }

  public show (): void {
    this.spinner.show()
    this.spinnerSubject.next(true)
  }

  public hide (): void {
    this.spinner.hide()
    this.spinnerSubject.next(false)
  }

  public getSpinnerState (): Observable<boolean> {
    return this.spinnerSubject.asObservable()
  }
}
