import { Component } from '@angular/core'
import { SpinnerService } from 'src/app/shared/services/spinner.service'

@Component({
  selector: `eld-spinner`,
  templateUrl: `./spinner.component.html`,
  styleUrl: `./spinner.component.scss`
})
export class SpinnerComponent {
  constructor (protected spinnerService: SpinnerService) { }
}
