<div class="container-fluid p-0 h-100-perc app-component-root" [attr.data-bs-theme]="themeService.theme | async">
  <eld-spinner *ngIf="embeddedMode"></eld-spinner>
  <div class="row header navbar-bg navbar m-0" *ngIf="!hideHeader && !embeddedMode">
    <ng-container *ngIf="headerService.button | async as button">
      <div class="text-secondary col-auto p-0 d-flex align-items-center m-right-2" (click)="button.callback()" role="button">
          <fa-icon [icon]="button.icon" class="me-2 text-3 lineh-3"></fa-icon>
          <span class="d-none d-sm-inline text-2">{{ 'BUTTON.' + button.label | translate }}</span>
      </div>
    </ng-container>
    <div class="col p-0 h-100-perc">
      <img class="logo-responsive" src="assets/imgs/logo/logo.png">
    </div>
    <div class="col-auto p-0 d-flex align-items-center">
      <eld-dark-mode-switch></eld-dark-mode-switch>
      <div class="nav-item dropdown col-auto p-0">
        <a class="nav-flag dropdown-toggle d-flex" href="#" id="languageDropdown" data-bs-toggle="dropdown">
          <img [src]="'assets/flags/' + currentLang?.value + '.png'" [alt]="currentLang?.name"/>
        </a>
        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="languageDropdown">
          <ng-container *ngFor="let locale of langs">
            <a class="dropdown-item" (click)="currentLang = locale">
              <img [src]="'assets/flags/' + locale.value + '.png'" [alt]="locale.name" width="20" class="align-middle me-1"/>
              <span class="align-middle">{{ locale.name }}</span>
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid router-container p-0">
    <router-outlet></router-outlet>
  </div>
</div>
