import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SpinnerComponent } from './spinner/spinner.component'
import { NgxSpinnerModule } from 'ngx-spinner'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

@NgModule({
  declarations: [SpinnerComponent],
  exports: [SpinnerComponent],
  imports: [
    CommonModule,
    NgbModule,
    NgxSpinnerModule
  ]
})
export class SpinnerModule { }
