import { ApplicationRef, Injectable, RendererFactory2 } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({ providedIn: `root` })
export class ThemeService {
  public theme = new BehaviorSubject<`light` | `dark`>((localStorage.getItem(`mode`) as `light` | `dark`) || `light`)

  constructor (
    private rendererFactory: RendererFactory2,
    private ref: ApplicationRef) {
    // if matchMedia is available we check the current color-scheme and
    // we listen to the change event if the device changes preferred-color-scheme
    if (window.matchMedia) {
      const darkModeOn = window
        .matchMedia(`(prefers-color-scheme: dark)`).matches

      const storedMode = localStorage.getItem(`mode`)
      if (storedMode) {
        this.theme.next(storedMode as `light` | `dark`)
      } else if (darkModeOn) {
        this.theme.next(`dark`)
      }
      window.matchMedia(`(prefers-color-scheme: dark)`)
        .addEventListener(`change`, ({ matches }) =>
          this.theme.next(matches ? `dark` : `light`))
    }

    // in services Renderer are not available, thus we have to create one
    const renderer = this.rendererFactory.createRenderer(null, null)
    const theme = document.querySelector(`head > #theme-stylesheet`)

    // On theme value update we change the imported theme in the html <head>
    this.theme.subscribe(styleName => {
      localStorage.setItem(`mode`, styleName)
      renderer.setAttribute(theme, `href`, `theme/css/${styleName}.css`)
      this.ref.tick()
    })
  }
}
