import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { HeaderService } from './shared/services/header.service'
import { ThemeService } from './shared/services/theme.service'
import { AuthService } from './shared/services/auth.service'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment'
import { SpinnerService } from './shared/services/spinner.service'

@Component({
  selector: `eld-root`,
  styleUrls: [`./app.component.scss`],
  templateUrl: `./app.component.html`
})
export class AppComponent implements OnInit {
  public langs = [{
    name: `Deutsch`,
    value: `de`
  }, {
    name: `English`,
    value: `en`
  }]

  public _currentLang = this.langs.find(({ value }) => value === `de`)

  // Embedding params
  public embeddedMode = false

  public hideHeader = false

  constructor (
    public headerService: HeaderService,
    public themeService: ThemeService,
    public translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private spinnerService: SpinnerService
  ) {
    // Embedding settings
    this.spinnerService.show()
    this.embedModeInit()

    // Language settings
    translate.setDefaultLang(this.currentLang.value)

    const lang = localStorage.getItem(`lang`) || translate.getBrowserLang()
    const langAvailable = this.langs.find(({ value }) => value === lang)

    if (langAvailable) {
      this.currentLang = langAvailable
    }
    localStorage.setItem(`lang`, this.translate.currentLang)
  }

  public ngOnInit (): void {
    // Camouflage the login, routing to targetUrl
    setTimeout(() => {
      this.spinnerService.hide()
    }, 2000)
  }

  private embedModeInit (): void {
    // Listen for embedding requests
    window.addEventListener(`message`, (message: MessageEvent) => {

      // Filter out unrelated messages (webpack msgs, malicious...)
      if (message.origin !== environment.appDashboardUrl ||
        (message?.data.type !== `embeddingMsg`)) {
        return
      }

      const { token, hideHeader, siteId } = message.data
      if (token) {
        // Set embedded mode flag (to apply certain scss in this mode only)
        this.embeddedMode = window.self !== window.top
        localStorage.setItem(`embeddedMode`, JSON.stringify(this.embeddedMode))

        // Save token
        this.authService.token.next(token)

        // Conditionally hide the header
        this.hideHeader = hideHeader === true

        // Re-route to the site view
        this.router.navigateByUrl(`/station/${siteId}`)
      }
    })
  }

  public set currentLang (l: {name: string, value: string }) {
    this._currentLang = l
    this.translate.use(this.currentLang.value)
  }

  public get currentLang () {
    return this._currentLang
  }

}
